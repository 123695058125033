
import {Component, defineComponent, ref} from 'vue';
import NavTab, {NavTabItem} from '@/components/common/NavTab.vue';
import {Portal} from 'portal-vue';
import Team from '@/models/Team';
import TeamMembers from '@/components/teams/teams/Members.vue';
import TeamPitches from '@/components/teams/teams/Pitches.vue';
import TeamProfileCard from '@/components/teams/teams/ProfileCard.vue';
import get from 'lodash/get';
import {useRoute} from 'vue-router';

interface EditTabComponent {
    key: 'pitches' | 'members' | 'profile';
    component: Component;
}

export default defineComponent({
    components: {
        Portal,
        NavTab,
        TeamMembers,
        TeamPitches,
        TeamProfileCard,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const route = useRoute();

        let viewKey = get(
            route,
            'query.view',
            'pitches',
        );

        viewKey = ['pitches', 'members', 'profile'].includes(viewKey)
            ? viewKey
            : 'pitches';

        return {
            activeView: ref<EditTabComponent['key']>(viewKey),
            tabComponents: [
                {
                    key: 'pitches',
                    component: TeamPitches,
                },
                {
                    key: 'members',
                    component: TeamMembers,
                },
                {
                    key: 'profile',
                    component: TeamProfileCard,
                },
            ] as EditTabComponent[],
            team: ref<Team>(new Team({
                id: +props.id,
            })),
        };
    },
    computed: {
        activeComponent(): Component {
            const activeTab = this.tabComponents.find((tabComponent: EditTabComponent) => tabComponent.key === this.activeView);

            return activeTab !== undefined
                ? activeTab.component
                : this.tabComponents[0].component;
        },
        tabs(): NavTabItem[] {
            return this.tabComponents.map((tab: Record<string, any>) => ({
                label: this.$t(`teams.edit.${tab.key}.nav`),
                value: tab.key,
                active: tab.key === this.activeView,
            }));
        },
    },
    created() {
        this.team.fetch();
    },
    methods: {
        updateTeam(team: Team) {
            this.team.fill(team.attributes);
        },
        setActiveView(view: EditTabComponent['key']) {
            this.activeView = view;

            this.$router.replace({
                name: this.$route.name!,
                query: {
                    view,
                },
            });
        },
    },
});
