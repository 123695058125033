
import {PropType, defineComponent, ref} from 'vue';
import {Pitches} from '@/models/Pitch';
import PitchesTable from '@/components/pitches/Table.vue';
import Team from '@/models/Team';

export default defineComponent({
    components: {
        PitchesTable,
    },
    props: {
        team: {
            type: Object as PropType<Team>,
            required: true,
        },
    },
    setup(props) {
        return {
            headers: [
                'name',
                'pitchMoment',
                'updatedAt',
                'createdAt',
            ],
            pitches: ref<Pitches>(new Pitches([], {
                include: ['pitchMoment'],
            })),
            options: {
                url: `/teams/${props.team.id}/pitches`,
            },
        };
    },
});
