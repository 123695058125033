import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PitchesTable = _resolveComponent("PitchesTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PitchesTable, {
      headers: _ctx.headers,
      options: _ctx.options,
      pitches: _ctx.pitches
    }, null, 8, ["headers", "options", "pitches"])
  ]))
}