import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center h-100" }
const _hoisted_2 = { class: "ms-6 me-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_Portal = _resolveComponent("Portal")!
  const _component_NavTab = _resolveComponent("NavTab")!
  const _component_WaitForResource = _resolveComponent("WaitForResource")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Portal, { to: "navbar-top-content" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_RouterLink, {
            class: "btn px-0",
            to: {name: 'teams.index'}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Fa, { icon: "arrow-left" })
            ]),
            _: 1
          }, 8, ["to"]),
          _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.team.name != null ? _ctx.team.name : _ctx.$t('teams.edit.title')), 1)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_Portal, { to: "navbar-top-append" }, {
      default: _withCtx(() => [
        _createVNode(_component_NavTab, {
          class: "w-100 mt-6 mb-n6",
          "no-border": "",
          tabs: _ctx.tabs,
          onTabClicked: _ctx.setActiveView
        }, null, 8, ["tabs", "onTabClicked"])
      ]),
      _: 1
    }),
    _createVNode(_component_WaitForResource, {
      "loading-property": "fetching",
      resource: _ctx.team
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.activeComponent), {
          team: _ctx.team,
          onTeamSaved: _ctx.updateTeam
        }, null, 8, ["team", "onTeamSaved"]))
      ]),
      _: 1
    }, 8, ["resource"])
  ]))
}